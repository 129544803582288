<template>
  <div class="section-main-100 pa-9">
    <h1 class="weight-900 text-h5 mb-6 text-uppercase">О нас</h1>
    <img
      alt=""
      :src="about"
      class="image"
    />
    <p class="description mt-6">
      Наше агентство создано в <span class="blue--text">2014</span> году коллективом опытных агентов , на тот момент
      работающих в недвижимости более 10
      лет. <br>
      Основное отличие нашего агентства – это то, что большинство наших клиентов приходят к нам по рекомендации. <br>
      Для удобства наших клиентов мы создали сайт, на котором размещены только реальные квартиры,<span class="blue--text"> без фейков и рекламы</span>.
      <br>
      Благодаря разработанной нами CRM-системе, наши клиенты получают информацию об актуальных вариантах по их запросу
      <span class="blue--text"> в течении часа</span> с момента обращения к нам.
    </p>
  </div>
</template>

<script>
import about from '@/assets/about.jpeg'

export default {
  name: 'AboutUs',
  data () {
    return {
      about
    }
  }
}
</script>

<style scoped>
  .image {
    width: 100%;
    height: 323px;
    object-fit: cover;
    object-position: center top;
    border-radius: 5px;
  }
  .description {
    max-width: 50%;
    line-height: 1.2;
  }
  @media (max-width: 800px) {
    .description {
      max-width: 100%;
    }
    .image {
      height: 250px;
    }
  }
</style>
